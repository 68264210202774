const mode = process.env.NEXT_PUBLIC_MODE;
const target = process.env.NEXT_PUBLIC_TARGET;
const isLocal = process.env.NEXT_PUBLIC_LOCAL_MODE;

const getOriginOpsURL = () => {
  if (mode === "development" && isLocal === "true") {
    return "http://localhost:8099";
  }
  if (target === "cs" && mode === "production") {
    return "https://cs.elecle.bike";
  }

  if (target === "cs" && mode === "development") {
    return "https://cs-dev-67ec9.web.app";
  }

  if (mode === "production") {
    return "https://ops.elecle.bike";
  }

  if (mode === "staging") {
    return "https://ops-staging.elecle.bike";
  }

  return "https://ops-dev.elecle.bike";
};

const ORIGIN_OPS = getOriginOpsURL();

const API_DOMAIN =
  mode === "production" || mode === "staging"
    ? "https://c.elecle.bike"
    : "https://c.elecle.me";

export const settings = {
  API_DOMAIN,
  ORIGIN_OPS
};
